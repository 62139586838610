import axios from '@/apis/axios';
import { getAccessToken, convertCamelToSnake } from '@/lib/api';

/**
 * 案件一覧取得
 */
export const getContracts = async (option, condition) => {
  const accessToken = await getAccessToken();

  let params = {};
  if (option) {
    params = Object.assign(params, convertCamelToSnake(option));
  }
  if (condition) {
    params = Object.assign(params, convertCamelToSnake(condition));
  }

  const response = await axios
    .get(`/agencies/contracts`, {
      params: { ...params },
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 案件情報取得
 * @param {Number} contractId 契約ID
 */
export const getContractDetail = async contractId => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get(`/agencies/contracts/${contractId}`, {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  // プラン情報
  const planInfo = response.data.plans.map(plan => ({
    id: plan['id'],
    planName: plan['planName'],
    monthly: plan['monthly'],
    yearly: plan['yearly'],
    baseInsurancePeriodYear: plan['baseInsurancePeriodYear'],
    baseInsurancePeriodMonthDay: plan['baseInsurancePeriodMonthDay'],
    baseInsurancePeriodMdfType: plan['baseInsurancePeriodMdfType'],
    earthquakeInsurancePeriod: plan['earthquakeInsurancePeriod'],
    earthquakeInsurancePeriodTypeCode:
      plan['earthquakeInsurancePeriodTypeCode'],
    payment: plan['payment'],
    status: plan['status'],
    attachDoc: plan['attachDoc'],
    message: plan['message'],
    comment: plan['comment'],
  }));

  // 基本情報
  const contractInfo = {
    id: response.data.id,
    acceptanceType: response.data.acceptanceType,
    policyNumber: response.data.policyNumber,
    previousPolicyNumber: response.data.previousPolicyNumber,
    printingSerialNumber: response.data.printingSerialNumber,
    status: response.data.status,
    fullNameKanji: `${response.data.fullNameKanji1}${
      response.data.fullNameKanji2 ? response.data.fullNameKanji2 : ''
    }${response.data.fullNameKanji3 ? response.data.fullNameKanji3 : ''}`,
    fullNameKana: `${response.data.fullNameKana1}${
      response.data.fullNameKana2 ? response.data.fullNameKana2 : ''
    }${response.data.fullNameKana3 ? response.data.fullNameKana3 : ''}`,
    organizationCode: response.data.organizationCode,
    organizationName: response.data.organizationName,
    insuranceName: response.data.applicationForm.insuranceType.name,
    productName: response.data.productName,
    startDate: response.data.startDate,
    contractorAddress: `${response.data.contractorAddress1}${
      response.data.contractorAddress2 ? response.data.contractorAddress2 : ''
    }${
      response.data.contractorAddress3 ? response.data.contractorAddress3 : ''
    }`,
    insuranceLocation: `${
      response.data.insuranceLocation1 ? response.data.insuranceLocation1 : ''
    }${
      response.data.insuranceLocation2 ? response.data.insuranceLocation2 : ''
    }${
      response.data.insuranceLocation3 ? response.data.insuranceLocation3 : ''
    }`,
    insuranceObject1: response.data.insuranceObject1,
    insuranceObject2: response.data.insuranceObject2,
    firstPayment: {
      accountTransferFlg: response.data.accountTransferFlg,
      creditCardFlg: response.data.creditCardFlg,
      paymentSlipFlg: response.data.paymentSlipFlg,
      cashPaymentFlg: response.data.cashPaymentFlg,
      debitCardFlg: response.data.debitCardFlg,
    },
    phoneNumber: response.data.phoneNumber,
    mail: response.data.mail,
    dateOfBirth: response.data.dateOfBirth,
    filledOutPdfPath: response.data.filledOutPdfPath,
    comment: response.data.comment,
    note: response.data.note,
    lockoutStatus: response.data.lockoutStatus,
    rightOfPledge: response.data.rightOfPledge,
    createdAt: response.data.createdAt,
  };

  // 代理店情報
  const agencyInfo = {
    id: response.data.agencyId,
    agencyName: response.data.agencyName,
    agencyNameContract: response.data.agencyNameContract,
    chargePersonId: response.data.chargePersonId,
    chargePersonName: response.data.chargePersonName,
    chargePersonNameContract: response.data.chargePersonNameContract,
    chargePersonCode: response.data.chargePersonCode,
    agencyPhoneNumber: response.data.agencyPhoneNumber,
    agencyMail: response.data.agencyMail,
    agencyCode: response.data.agencyCode,
    agencyCodeUser: response.data.agency
      ? response.data.agency.agencyCode
      : null,
    agencyMessage: response.data.agencyMessage,
  };

  // 営業店情報
  const salesInfo =
    response.data.agency && response.data.agency.sale
      ? {
          id: response.data.agency.sale.id,
          salesDepartmentName: response.data.agency.sale.salesDepartmentName,
          salesSectionName: response.data.agency.sale.salesSectionName,
          salesCode: response.data.agency.sale.salesCode,
        }
      : null;

  // 送信情報
  const submitInfo = response.data.contractSubmits.map(submit => ({
    id: submit['id'],
    planId: submit['planId'],
    contractDate: submit['contractDate'],
    createdAt: submit['createdAt'],
    modificationRequests: submit['modificationRequests'].map(
      modificationRequest => ({
        requestContent: modificationRequest['requestContent'],
        coordinateManagement: modificationRequest['coordinateManagement'],
        modificationItem: modificationRequest['modificationItem'],
        plan: modificationRequest['plan'],
      })
    ),
  }));

  // お客様画像情報
  const insuredUploadInfo = response.data.insuredUploads.map(insuredUpload => ({
    id: insuredUpload['id'],
    fileName: insuredUpload['fileName'],
  }));

  // その他情報
  const otherInfo = {
    applicationFormId: response.data['applicationFormId'],
    filledOutPdfPath: response.data['filledOutPdfPath'],
    importantMatter:
      response.data.applicationForm.insuranceType.importantMatter,
    internetCovenant:
      response.data.applicationForm.insuranceType.internetCovenant,
    internetCovenantImage:
      response.data.applicationForm.insuranceType.internetCovenantImage,
  };

  return {
    planInfo: planInfo,
    contractInfo: contractInfo,
    agencyInfo: agencyInfo,
    salesInfo: salesInfo,
    submitInfo: submitInfo,
    insuredUploadInfo: insuredUploadInfo,
    otherInfo: otherInfo,
  };
};

/**
 * 案件対応重要事項等説明書取得
 */
export const getContractImportantMatter = async contractId => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get(`/agencies/contracts/${contractId}/important_matters`, {
      responseType: 'blob',
      dataType: 'application/pdf',
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });
  if (!response) return;

  // typeの実態がapplication/octet-streamになっているためpdfに書き換える
  const blob = new Blob([response.data]);

  return blob;
};

/**
 * 案件重要事項等説明書URL取得
 */
export const getContractImportantMatterUrl = async contractId => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get(`/agencies/contracts/${contractId}/important_matters/url`, {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * インターネット特約条項取得
 */
export const getContractInternetCovenant = async contractId => {
  const accessToken = await getAccessToken();
  const response = await axios
    .get(`/agencies/contracts/${contractId}/internet_covenants`, {
      responseType: 'blob',
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });
  return new Blob([response.data]);
};

/**
 * インターネット特約条項URL取得
 */
export const getContractInternetCovenantUrl = async contractId => {
  const accessToken = await getAccessToken();
  const response = await axios
    .get(`/agencies/contracts/${contractId}/internet_covenants/url`, {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });
  return response.data;
};

/**
 * 案件情報登録
 */
export const postContract = async data => {
  const accessToken = await getAccessToken();

  const response = await axios
    .post(
      `/agencies/contracts`,
      { ...data },
      {
        headers: { 'access-token': accessToken },
      }
    )
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 案件情報更新
 * @param {Object} condition 更新項目一覧
 */
export const updateContractDetail = async condition => {
  const accessToken = await getAccessToken();

  const contractId = condition.contractId;

  const response = await axios.put(
    `/agencies/contracts/${contractId}`,

    { ...condition },
    { headers: { 'access-token': accessToken } }
  );
  return response.data;
};

/**
 * 案件削除
 */
export const deleteContract = async contractId => {
  const accessToken = await getAccessToken();

  const response = await axios
    .delete(`/agencies/contracts/${contractId}`, {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });
  return response.data;
};

/**
 * 案件ステータス更新
 */
export const putContractStatus = async (contractId, updateParam) => {
  const accessToken = await getAccessToken();

  const response = await axios
    .put(
      `/agencies/contracts/${contractId}/status`,
      { ...updateParam },
      { headers: { 'access-token': accessToken } }
    )
    .catch(error => {
      throw error.response.data;
    });
  return response.data;
};

/**
 * プラン情報更新
 */
export const updatePlanDetail = async (contractId, planId, data) => {
  const accessToken = await getAccessToken();

  const response = await axios
    .put(
      `/agencies/contracts/${contractId}/plans/${planId}`,
      { ...data },
      { headers: { 'access-token': accessToken } }
    )
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * メール・SMS送信
 * @param {Object} condition 送信情報一覧
 */
export const sendMessage = async condition => {
  const accessToken = await getAccessToken();

  const contractId = condition.contractId;

  const response = await axios
    .put(
      `/agencies/contracts/${contractId}/contract_submits`,
      { ...condition },
      {
        headers: { 'access-token': accessToken },
      }
    )
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 案件申込書取得
 */
export const getContractApplicationForm = async contractId => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get(`/agencies/contracts/${contractId}/application_form`, {
      responseType: 'blob',
      dataType: 'application/pdf',
      headers: {
        'access-token': accessToken,
      },
    })
    .catch(error => {
      throw error.response.data;
    });

  const blob = new Blob([response.data]);

  return blob;
};

/**
 * 案件申込書URL取得
 */
export const getContractApplicationFormUrl = async contractId => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get(`/agencies/contracts/${contractId}/application_form/url`, {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 申込書以外データ情報取得処理
 */
export const getExtraPictureList = contractId =>
  new Promise(async (resolve, reject) => {
    const accessToken = await getAccessToken();

    const params = { ['contractId']: contractId };

    const response = await axios.get(
      `/agencies/contracts/${contractId}/extra_files/list`,
      {
        params: { ...params },
        headers: {
          'access-token': accessToken,
        },
      }
    );

    resolve(response.data);
  });

/**
 * 申込書以外データ取得処理
 */
export const getExtraPicture = (contractId, pictureId) =>
  new Promise(async (resolve, reject) => {
    const accessToken = await getAccessToken();

    const params = { ['id']: pictureId };

    const response = await axios.get(
      `/agencies/contracts/${contractId}/extra_files/file`,
      {
        params: { ...params },
        responseType: 'arraybuffer',
        headers: {
          'access-token': accessToken,
        },
      }
    );
    if (!response) return resolve(null);

    resolve(new Blob([response.data]));
  });

/**
 * 申込書以外データ登録処理
 */
export const registerExtraPictures = async (
  contractId,
  extraFileDetails,
  picture
) => {
  const accessToken = await getAccessToken();

  const form = new FormData();
  form.append('files[0]', picture);
  form.append('extraFileDetails', JSON.stringify(extraFileDetails));

  const response = await axios
    .post(`/agencies/contracts/${contractId}/register_extra_files`, form, {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });
  return response.data;
};

/**
 * 申込書以外データ内容編集処理
 */
export const editExtraPictures = async (
  contractId,
  extraFileId,
  extraFileDetails
) => {
  const accessToken = await getAccessToken();
  const response = await axios
    .put(
      `/agencies/contracts/${contractId}/register_extra_files/${extraFileId}`,
      { ...extraFileDetails },
      {
        headers: { 'access-token': accessToken },
      }
    )
    .catch(error => {
      throw error.response.data;
    });
  return response.data;
};

/**
 * 申込書以外データ削除処理
 */
export const deleteExtraPictures = async (contractId, extraFileId) => {
  const accessToken = await getAccessToken();
  const response = await axios
    .delete(
      `/agencies/contracts/${contractId}/register_extra_files/${extraFileId}`,
      {
        headers: { 'access-token': accessToken },
      }
    )
    .catch(error => {
      throw error.response.data;
    });
  return response.data;
};

/**
 * ロックアウト解除処理
 */
export const lockoutRelease = async contractId => {
  const accessToken = await getAccessToken();
  const response = await axios
    .put(
      `/agencies/contracts/${contractId}/lockout_release`,
      {},
      {
        headers: { 'access-token': accessToken },
      }
    )
    .catch(error => {
      throw error.response.data;
    });
  return response.data;
};
